<template>
    <div>
      <!-- Navbar -->
        <nav class="navbar navbar-color" role="navigation" aria-label="main navigation">
            <div class="container">
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item">
                        <img src="../../../public/logo.png" width="50" height="50">
                        <h1 class="heading">Square<span>Garden</span></h1>
                    </router-link>
                <span class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarHomepage" @click="toggleNavmenu">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </div>
            <div id="navbarHomepage" class="navbar-menu" :class="mobile">
                <div class="navbar-start">
                    <div class="navbar-item">
                        <button id="installbutton" class="button is-success is-inverted install-button hide"><i class="fas fa-download"></i>installieren</button>
                        <span id="iOSInstall" class="hide">To install press <img src="../../../public/img/add-to-homescreen.png" alt="add-to-home-screen" class=ios> and add to Homescreen</span>
                    </div>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <span class="link ix-active"><router-link to="/product" style="color: black;">{{ $t("nav.product.value")}}</router-link></span>
                    </div>
                    <div class="navbar-item">
                        <span class="link"><router-link to="/contact" style="color: black;">{{ $t("nav.contact.value")}}</router-link></span>
                    </div>
                    <div class="navbar-item">
                        <div class="buttons">
                            <router-link to="/login" class="button is-success is-inverted"><i class="far fa-user"></i>Login</router-link>
                            <router-link to="/register" class="button is-success is-inverted"><i class="far fa-envelope-open"></i>{{ $t("nav.register.value")}}</router-link>
                            <languageSwitcher />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </nav>
        <div class="container">
            <section class="header">
                <h1 class="product-heading">{{ $t("product.productHeading.value") }}</h1>
                <p class="intro">
                    {{ $t("product.subheading.heading") }}
                </p>
                <p class="intro-text">
                    {{ $t("product.subheading.text") }}
                </p>
            </section>
            <section>
                <div class="card boxed left">
                    <div class="image">
                        <img src="../../../public/img/sg/sg-home.png" alt="square garden home">
                    </div>
                    <div>
                        <h2 class="subheading">{{ $t("product.homeCard.heading") }}</h2>
                        <p class="text">
                            {{ $t("product.homeCard.text") }}
                        </p>
                    </div>
                </div>
            
                <div class="card boxed right">
                    <div>
                        <h2 class="subheading">{{ $t("product.gardenAreaCard.heading")}}</h2>
                        <p class="text">
                            {{ $t("product.gardenAreaCard.text")}}
                        </p>
                    </div>
                    <div class="image">
                        <img src="../../../public/img/sg/sg-garden.png" alt="">
                    </div>
                </div>
                <div class="card boxed left">
                    <div class="image">
                        <img src="../../../public/img/sg/garden-menu.png" alt="">
                    </div>
                    <div>
                        <h2 class="subheading">
                            {{ $t("product.gardenMenuCard.heading")}}
                        </h2>
                        <p class="text">
                            {{ $t("product.gardenMenuCard.text")}}  
                        </p>
                    </div>
                </div>
                <div class="card boxed right">
                    <div>
                        <h2 class="subheading">
                            {{ $t("product.seasonCard.heading")}}
                        </h2>
                        <p class="text">
                            {{ $t("product.seasonCard.text")}}
                        </p>
                    </div>
                    <div class="image">
                        <img src="../../../public/img/sg/seasonal-ending.png" alt="">
                    </div>
                </div>
            </section>

            <section class="foot">
                <h2 class="subheading">{{ $t("product.register.text")}}</h2>
                <router-link to="/register" class="button is-success is-inverted"><i class="far fa-envelope-open"></i>{{ $t("product.register.value")}}</router-link>
            </section>
        </div>
    </div>
</template>

<script>
import languageSwitcher from "@/components/multilang/languageSwitcher";

export default {
    name: "Product",
    components: {
        languageSwitcher,
    },
    data() {
        return {
            mobile: "",
        }
    },
    methods: {
        //UI Methods
        toggleNavmenu(e) {
            //Toggling the burger-menu
            if(!e.target.classList.contains("is-active")){
                e.target.classList.add("is-active")
                this.mobile="is-active";
            } else {
                e.target.classList.remove("is-active");
                this.mobile="";
            }
        }
    }
}
</script>

<style scoped>
.navbar-color {
    background: #94C973;
}

h1.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
}

h1.product-heading {
    color: #107869;
    font-size: 2.2rem;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.header {
    margin-bottom: 50px;
}

.intro {
    font-weight: 500;
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 16px;
}

.intro-text {
    font-weight: 400;
    text-align: center;
    font-size: 1.1rem;
}

h2.subheading {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    text-align: center;
}

img.ios {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.link{
    font-size: 1.1rem;
    font-weight: 500;
}

i{
    margin-right: 5px;
}

img {
    border-radius: 15px;
    margin-top: 10px;
}

.image {
    max-width: 700px;
    margin: 0 auto;
}

.card {
    max-width: 1200px;
    padding: 5px;
    margin: 20px 10px;
}

.text {
    margin: 0 10px;
    font-size: 1em;
    margin-bottom: 10px;
}

.hide {
  visibility: hidden;
}

.foot {
    text-align: center;
    padding-bottom: 20px;
}

@media screen and (min-width: 600px) {
    .boxed {
        display: grid;
        align-items: center;
        text-align: center;
    }

    .left {
        grid-template-columns: 2fr 1fr;
    }

    .right {
        grid-template-columns: 1fr 2fr;
    }
}

</style>